<template>
  <div class="MessageList">
    <MessageMenu />
    <!-- 筛选项 -->
    <div class="wrap">
      <el-form :inline="true">
        <el-form-item label="设备类型">
          <span class="particularType" @click="particularType = null" :class="[particularType == null ? 'particularTypeActive': '']">不限</span>
          <span
            class="particularType"
            :class="[particularType == item.id ? 'particularTypeActive': '']"
            v-for="(item, index) in particularTypeList"
            :key="index"
            @click="particularType = item.id"
          >
            {{ item.label }}
          </span>
        </el-form-item>
      </el-form>
      <el-form :inline="true">
        <el-form-item label="项目名称">
          <el-select
            size="small"
            v-model="currentProject"
            placeholder="请选择项目"
          >
            <el-option
              v-for="item in projectList"
              :key="item.id"
              :label="item.proName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>

    <!-- 功能按钮 -->
    <div class="wrap-blank">
      <el-button
        size="small"
        plain
        type="primary"
        icon="el-icon-plus"
        @click="openConfig('insert', null)"
        >添加短信预警</el-button
      >
    </div>

    <!-- 报警列表 -->
    <div class="wrap">
      <el-table
        :data="gdAlarmConfigList"
        style="width: 100%"
        @row-click="openLogDialog"
      >
        <el-table-column prop="deviceName" label="设备名称"> </el-table-column>
        <el-table-column prop="userName" label="用户姓名"> </el-table-column>
        <el-table-column prop="userMobile" label="手机号"> </el-table-column>
        <el-table-column prop="alarmInterval" label="报警间隔（小时）"> </el-table-column>
        <el-table-column prop="lastTime" label="报警时间"> </el-table-column>
        <el-table-column label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click.stop="openConfig('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click.stop="delConfig(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize"
        :current-page.sync="pageNum"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals"
        @current-change="getGdAlarmConfigList"
        @size-change="getGdAlarmConfigList"
      >
      </el-pagination>
    </div>

    <!-- 报警记录 -->
    <el-dialog
      title="报警记录"
      custom-class="wg-record"
      :visible.sync="dialogTableVisible"
      @close="closeLogDialog"
    >
      <el-table :data="gdAlarmLogs" style="width: 100%">
        <el-table-column width="150" label="设备名称">
          <template>
            <span v-if="currentQueryObj">
              {{currentQueryObj.deviceName}}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="userName" width="100" label="用户姓名"></el-table-column>
        <el-table-column prop="phone" width="120" label="手机号"></el-table-column>
        <el-table-column prop="createTime" width="180" label="报警时间"></el-table-column>
        <el-table-column prop="logText" label="报警信息"></el-table-column>
      </el-table>
      <el-pagination
        layout="total, sizes, prev, pager, next"
        :page-size.sync="pageSize2"
        :current-page.sync="pageNum2"
        :page-sizes="[2, 5, 10, 20, 50]"
        :total="totals2"
        @current-change="getLogData"
        @size-change="getLogData"
      >
      </el-pagination>
    </el-dialog>

    <!-- 报警设置表单 -->
    <el-dialog
      title="短信预警"
      custom-class="config-form"
      :visible.sync="showConfig"
      @close="closeConfig"
    >
      <el-descriptions
        title="请填写短信预警信息"
        labelClassName="labelClassName"
        :column="1"
        border
      >
        <template slot="extra">
          <el-button
            type="primary"
            icon="el-icon-check"
            size="small"
            @click="saveConfig"
            >确定</el-button
          >
        </template>
        <el-descriptions-item label="设备类型">
          <el-select v-model="currentParticularType" style="width: 100%" placeholder="请选择">
            <el-option
              v-for="item in particularTypeList"
              :key="item.id"
              :label="item.label"
              :value="item.id">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="设备名称">
          <el-select v-model="currentParticularId" style="width: 100%" placeholder="请选择">
            <el-option
              v-for="item in particularList"
              :key="item.id"
              :label="item.deviceName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item label="用户姓名">
          <el-input
            v-model="configData.userName"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="手机号">
          <el-input
            v-model="configData.userMobile"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
        <el-descriptions-item label="报警间隔">
          <el-input
            v-model="configData.alarmInterval"
            placeholder="请输入内容"
            clearable
          ></el-input>
        </el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<style lang="less" scope>
.MessageList {
  .el-form-item {
    margin-bottom: 0;
  }
  #video-container {
    width: 768px;
    height: 432px;
    background-color: #000;
  }
  .labelClassName {
    width: 120px;
  }

  .particular-item {
    margin-bottom: 20px;
    border-bottom: 1px dashed #d5d9e4;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .particularType {
    font-weight: 700;
    color: #409eff;
    padding: 5px 5px;
  }
  .particularTypeActive {
    color: #fff;
    background-color: #409eff;
    border-radius: 5px;
  }
  .wg-record {
    width: 1200px;
    background-color: #f0f0f0;
  }
  .config-form {
    width: 500px;
    background-color: #f0f0f0;
  }
  .labelClassName {
    width: 120px;
  }
}
</style>

<script>
import MessageMenu from "@/components/MessageMenu.vue";

export default {
  name: "MessageList",
  components: {
    MessageMenu,
  },
  data() {
    return {
      projectList: [],
      currentProject: null,
      //1:塔机  2：卸料机 3：升降机   4:扬尘   5：用电   6：反光背心  7：安全冒摄像头 8:建大仁科扬尘设备
      particularTypeList: [
        {id:1, label: '塔机'},
        {id:2, label: '卸料机'},
        {id:3, label: '升降机'},
        // {id:4, label: '扬尘'},
        {id:5, label: '用电'},
        // {id:6, label: '反光背心'},
        // {id:7, label: '安全冒摄像头'},
        {id:8, label: '扬尘设备'},
      ],
      particularType: null,

      gdAlarmConfigList: [],
      pageNum: 1,
      pageSize: 10,
      totals: 0,

      dialogTableVisible: false,
      currentQueryObj: null,
      gdAlarmLogs: [],
      pageNum2: 1,
      pageSize2: 10,
      totals2: 0,

      showConfig: false,
      particularList: [],
      currentParticularType: 1,
      currentParticularId: null,
      currentConfigId: null,
      configData: {
        userName: null,
        userMobile: null,
        alarmInterval: null,
      },
      action: null,
    };
  },
  computed: {},
  mounted() {
    this.getProjectList(); // 加载项目列表数据
  },
  watch: {
    currentProject: function (val) {
      this.getGdAlarmConfigList(); // 获取设备列表
    },
    particularType: function (val) {
      this.getGdAlarmConfigList(); // 获取设备列表
    },
    currentParticularType: function (val) {
      this.getParticularList(); // 获取设备列表
      this.currentParticularId = null
    }
  },
  methods: {
    // 加载项目列表数据
    getProjectList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        proName: "",
        projectSysNo: "",
        proAddress: "",
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/project/projectSearch",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.projectList = res.data.projectList;
          if (res.data.projectList.length) {
            that.currentProject = res.data.projectList[0].id;
          }
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 加载工地报警设置列表
    getGdAlarmConfigList: function () {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: that.pageNum,
        pageSize: that.pageSize,
        projectId: that.currentProject,
        particularType: that.particularType,
        deviceName: '',
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/gdAlarmConfig/getPage",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.totals = res.data.totals;
          that.gdAlarmConfigList = res.data.gdAlarmConfigList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开报警记录窗口
    openLogDialog: function (row, column, event) {
      console.log('openLogDialog=>', row);
      this.dialogTableVisible = true
      this.currentQueryObj = row
      this.getLogData()
    },
    // 关闭报警记录窗口
    closeLogDialog: function () {
      this.dialogTableVisible = false
      this.currentQueryObj = null
      this.gdAlarmLogs = []
      this.pageNum2 = 1
      this.pageSize2 = 10
      this.totals2 = 0
    },
    // 获取报警记录数据
    getLogData: function (id) {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        pageNum: that.pageNum2,
        pageSize: that.pageSize2,
        id: that.currentQueryObj.id
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/gdAlarmLogs/inquireGdAlarmLogs",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.totals2 = res.data.totals;
          that.gdAlarmLogs = res.data.GdAlarmLogsList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 打开编辑、添加窗口
    openConfig: function (action, obj) {
      console.log('openConfig =>', obj);
      this.showConfig = true
      this.action = action
      if (action == 'insert') {
        this.currentConfigId = null
        this.currentParticularType = 1
        this.currentParticularId = null
        this.configData.userName = null
        this.configData.userMobile = null
        this.configData.alarmInterval = null
      }
      if (action == 'edit') {
        this.currentConfigId = obj.id
        this.currentParticularType = obj.particularType
        setTimeout(() => {          
          this.currentParticularId = parseInt(obj.particularId)
          this.configData.userName = obj.userName
          this.configData.userMobile = obj.userMobile
          this.configData.alarmInterval = obj.alarmInterval
        }, 100);
      }
      this.getParticularList()
    },
    // 关闭编辑、天级窗口
    closeConfig: function () {
      this.showConfig = false
    },
    saveConfig: function () {
      var that = this
      var data = {}
      var url = '/api/admin/gdAlarmConfig/' + this.action
      console.log('openConfig =>', url);
      if (this.action == 'insert') {
        data.id = null
        data.projectId = this.currentProject
        data.particularType = this.currentParticularType
        data.particularId = this.currentParticularId
        data.userName = this.configData.userName
        data.userMobile = this.configData.userMobile
        data.alarmInterval = this.configData.alarmInterval
      }
      if (this.action == 'edit') {
        data.id = this.currentConfigId
        data.projectId = this.currentProject
        data.particularType = this.currentParticularType
        data.particularId = this.currentParticularId
        data.userName = this.configData.userName
        data.userMobile = this.configData.userMobile
        data.alarmInterval = this.configData.alarmInterval
      }
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      that
        .$axiosAdmin({
          method: "post",
          url: url,
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.getGdAlarmConfigList() // 更新报警设置列表
          that.closeConfig() // 关闭短信报警配置窗口
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 获取特种设备列表
    getParticularList: function () {
      var that = this;
      that.radio = null;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        projectId: that.currentProject,
        deviceType: that.currentParticularType,
        pageNum: 1,
        pageSize: 100,
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/particular/queryPage",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.particularList = res.data.particularList;
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    },
    // 删除报警设置
    delConfig: function (data) {
      var that = this;
      that.loading = that.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      var data = {
        id: data.id
      };
      that
        .$axiosAdmin({
          method: "post",
          url: "api/admin/gdAlarmConfig/deleteById",
          data: JSON.stringify(data),
        })
        .then((res) => {
          that.getGdAlarmConfigList() // 更新报警设置列表
          that.loading.close();
        })
        .catch((err) => {
          console.log(err);
          that.loading.close();
        });
    }
  },
};
</script>